import { any, flip, test } from 'ramda';

/**
 * Converts a noun to the possessive form
 * Eg. Tom => Tom's, Chris => Chris'
 * @param str
 * @returns string
 */
export const makePossessive = (str: string = ''): string => {
  if (!str.length) {
    return '';
  }
  return str[str.length - 1].toLowerCase() === 's' ? `${str}'` : `${str}'s`;
};

/**
 * Returns true if the string matches any of the given Regular Expressions
 * @param str - string to check
 * @param regexArr - array of regular expressions
 */
export const matchesAny = (str: string = '', regexArr: Array<RegExp> = []): boolean => {
  if (!Array.isArray(regexArr)) {
    return false;
  }
  // @ts-ignore FIXME
  return any(flip(test)(str), regexArr);
};

/**
 * Returns true if a given URL contains http(s)
 * @param url
 */
export const isExternalURL = (url: string) => {
  const httpMatch = /\b(http|https)/;
  return httpMatch.test(url);
};

/**
 * Removes trailing slashes from a URL
 */
export const removeTrailingSlash = (url: string) => (url || '').replace(/[/]+$/, '');

/**
 * Removes forward slash from a URL
 */
export const removeForwardSlash = (url: string) => (url || '').replace(/^[/]+/, '');
